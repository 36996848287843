var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":"0.6","persistent":"","max-width":"1024","value":_vm.value}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-mail")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.editingMailing ? _vm.$t("mailing-edit-modify") : _vm.$t("mailing-edit-add")))]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"vertical-spacer"}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required","immediate":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('mailing-edit-hint-name'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.mailing.name),callback:function ($$v) {_vm.$set(_vm.mailing, "name", $$v)},expression:"mailing.name"}})]}}],null,true)})],1),_c('v-col',[_c('v-select',{attrs:{"outlined":"","dense":"","label":_vm.$t('mailing-edit-hint-type'),"item-text":"name","item-value":"id","items":_vm.types},model:{value:(_vm.mailing.type),callback:function ($$v) {_vm.$set(_vm.mailing, "type", $$v)},expression:"mailing.type"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"outlined":"","dense":"","label":_vm.$t('mailing-edit-hint-targets'),"item-text":"name","item-value":"id","items":_vm.targets},model:{value:(_vm.mailing.target),callback:function ($$v) {_vm.$set(_vm.mailing, "target", $$v)},expression:"mailing.target"}})],1),(_vm.mailing.target == 0)?_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":true,"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('mailing-edit-hint-recipients'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.mailing.recipient),callback:function ($$v) {_vm.$set(_vm.mailing, "recipient", $$v)},expression:"mailing.recipient"}})]}}],null,true)})],1):_vm._e(),(_vm.mailing.target == 1)?_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"outlined":"","dense":"","label":_vm.$t('mailing-edit-hint-category'),"item-text":"name","item-value":"id","required":"","items":_vm.categories},model:{value:(_vm.mailing.category),callback:function ($$v) {_vm.$set(_vm.mailing, "category", $$v)},expression:"mailing.category"}})]}}],null,true)})],1):_vm._e(),(_vm.mailing.target == 2)?_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"readonly":"","hide-details":"","outlined":"","dense":"","label":_vm.$t('customer-editor-hint-color')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.swatchStyle)},on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.mailing.color),callback:function ($$v) {_vm.$set(_vm.mailing, "color", $$v)},expression:"mailing.color"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.mailing.color),callback:function ($$v) {_vm.$set(_vm.mailing, "color", $$v)},expression:"mailing.color"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$t('mailing-edit-hint-subject'),"error-messages":errors,"success":valid,"required":"","immediate":true},model:{value:(_vm.mailing.subject),callback:function ($$v) {_vm.$set(_vm.mailing, "subject", $$v)},expression:"mailing.subject"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('quill-editor',{ref:"myQuillEditor",attrs:{"options":_vm.editorOption},model:{value:(_vm.mailing.content),callback:function ($$v) {_vm.$set(_vm.mailing, "content", $$v)},expression:"mailing.content"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"width":"120","disabled":invalid || !validated,"color":"orange"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]),_c('v-btn',{attrs:{"width":"120","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }