var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay-opacity":"0.6","max-width":"800","value":_vm.value}},[_c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"pa-0",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-car")])],1),_c('v-col',{staticClass:"pa-0 card-title-text",staticStyle:{"font-size":"25px"},attrs:{"align":"center","cols":"10"}},[_vm._v(_vm._s(_vm.$t("vehicle-edit-title")))]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"large":""},on:{"click":_vm.closeDialog}},[_vm._v("mdi-close")])],1)],1)]},proxy:true}])},[(_vm.fetching)?_c('div',[_c('div',{staticStyle:{"height":"10px"}}),_c('v-progress-circular',{attrs:{"enableExif":true,"indeterminate":true,"rotate":0,"size":32,"width":4,"color":"primary"}})],1):_vm._e(),_c('div',{staticStyle:{"height":"20px"}}),(!_vm.fetching)?_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":_vm.editingVehicle,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('vehicle-edit-hint-name'),"error-messages":errors,"success":valid,"required":"","outlined":"","dense":""},model:{value:(_vm.vehicle.name),callback:function ($$v) {_vm.$set(_vm.vehicle, "name", $$v)},expression:"vehicle.name"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"immediate":_vm.editingVehicle,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":_vm.$t('vehicle-edit-hint-plate'),"outlined":"","dense":"","error-messages":errors,"success":valid},model:{value:(_vm.vehicle.plate),callback:function ($$v) {_vm.$set(_vm.vehicle, "plate", $$v)},expression:"vehicle.plate"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('vehicle-edit-hint-description'),"outlined":"","dense":""},model:{value:(_vm.vehicle.description),callback:function ($$v) {_vm.$set(_vm.vehicle, "description", $$v)},expression:"vehicle.description"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$t('vehicle-edit-hint-notes'),"outlined":"","dense":""},model:{value:(_vm.vehicle.notes),callback:function ($$v) {_vm.$set(_vm.vehicle, "notes", $$v)},expression:"vehicle.notes"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"width":"120","disabled":invalid || !validated,"color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(_vm._s(_vm.$t("gbl-ok")))]),_c('v-btn',{attrs:{"width":"120","color":"secondary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("gbl-cancel")))])],1)],1)]}}],null,false,2645584733)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }