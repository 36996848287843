var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"justify-center":"","fluid":"","grid-list-xl":""}},[_c('messageBox',{ref:"msgBox"}),_c('mailingEditor',{ref:"mailingEditor",on:{"mailingConfigured":_vm.handleConfiguration},model:{value:(_vm.mailingEditorDialog),callback:function ($$v) {_vm.mailingEditorDialog=$$v},expression:"mailingEditorDialog"}}),_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('div',{staticStyle:{"height":"10px"}}),(_vm.$store.state.hasRight('218'))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addCampaign()}}},[_vm._v(_vm._s(_vm.$t("mailmng-add")))]):_vm._e(),_c('v-flex',{attrs:{"md12":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","icon":"mdi-mail","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light card-header"},[_vm._v(" "+_vm._s(_vm.$t("mailmng-title"))+" ")])]},proxy:true}])},[_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.mailings,"options":_vm.options,"server-items-length":_vm.totalMailings,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == 0 ? _vm.$t("mailmng-paused") : item.status == 1 ? _vm.$t("mailmng-started") : _vm.$t("mailmng-completed"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.hasRight('220') && item.status == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.editCampaign(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("mailmng-duplicate")))])]):_vm._e(),(_vm.$store.state.hasRight('221'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.duplicateCampaign(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("mailmng-duplicate")))])]):_vm._e(),(_vm.$store.state.hasRight('222') && item.status == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.startCampaign(item)}}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("mailmng-start")))])]):_vm._e(),(_vm.$store.state.hasRight('223') && item.status == 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"orange"},on:{"click":function($event){$event.stopPropagation();return _vm.pauseCampaign(item)}}},on),[_c('v-icon',[_vm._v("mdi-pause")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("mailmng-pause")))])]):_vm._e(),(_vm.$store.state.hasRight('219'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("mailmng-remove")))])]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }